.react-calendar {
    width: 350px;
    background: #FCFCFC;
    border-radius: 24px;
    font-family: Montserrat, sans-serif;
    line-height: 1.125em;
    width: 90%;
    padding: 24px;
}
.react-calendar--doubleView {
    width: 700px;
}
.react-calendar--doubleView .react-calendar__viewContainer {
    display: flex;
    margin: -0.5em;
}
.react-calendar--doubleView .react-calendar__viewContainer > * {
    width: 50%;
    margin: 0.5em;
}
.react-calendar,
.react-calendar *,
.react-calendar *:before,
.react-calendar *:after {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}
.react-calendar button {
    margin: 0;
    border: 0;
    outline: none;
}
.react-calendar button:enabled:hover {
    cursor: pointer;
}
.react-calendar__navigation {
    display: flex;
    justify-content: center;
    height: 44px;
    width: 100%;
    margin-bottom: 1em;
}

.react-calendar__navigation__next2-button {
    display: none;
}
.react-calendar__navigation__prev2-button {
    display: none;
}
.react-calendar__navigation__label {
    flex-grow: 0 !important;
}
.react-calendar__navigation button {
    font-family: Montserrat, sans-serif;
    min-width: 44px;
    background: none;
}
.react-calendar__navigation button:disabled {
    background-color: #f0f0f0;
}
.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
}
.react-calendar__month-view__weekdays {
    text-align: center;
    font-weight: bold;
    font-size: 0.75em;
    background: #F4F6F8;
    border: 1px solid #E6E6E6;
    border-radius: 8px 8px 0px 0px;
}
.react-calendar__month-view__weekdays__weekday {
    padding: 0.5em;
}
.react-calendar__month-view__weekdays__weekday>abbr {
    text-decoration: none;
}
.react-calendar__month-view__weekNumbers .react-calendar__tile {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.75em;
    font-weight: bold;
}
.react-calendar__month-view__days {
    border: 1px solid #E6E6E6 !important;
}
.react-calendar__month-view__days__day--weekend {
    background-color: #F4F6F8 !important;
}
.react-calendar__month-view__days__day--neighboringMonth {
    color: #757575;
    opacity: 0.32;
}
.react-calendar__month-view__days__day {
    text-decoration: none !important;
    position: relative;
}

.react-calendar__month-view__days__day>abbr {
    font-weight: 600;
    position: absolute;
    top: 14px;
    right: 20px;
}

.react-calendar__month-view__days__day>div {
    position: absolute;
    bottom: 8px;
    left: 8px;
    width: calc(100% - 16px);
}
.react-calendar__year-view .react-calendar__tile,
.react-calendar__decade-view .react-calendar__tile,
.react-calendar__century-view .react-calendar__tile {
    padding: 2em 0.5em;
}
.react-calendar__tile {
    max-width: 100%;
    padding: 10px 6.6667px;
    background: none;
    text-align: center;
    line-height: 16px;
    font-family: Montserrat, sans-serif;
    border: 1px solid #E6E6E6 !important;
    height: 200px;
}
.react-calendar__tile:disabled {
    background-color: #f0f0f0;
}
.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
    background-color: #e6e6e6;
}
.react-calendar__tile--now {
}
.react-calendar__tile--now:enabled:hover,
.react-calendar__tile--now:enabled:focus {
    background: #ffffa9;
}
.react-calendar__tile--hasActive {
}
.react-calendar__tile--hasActive:enabled:hover,
.react-calendar__tile--hasActive:enabled:focus {
    background: #a9d4ff;
}
.react-calendar__tile--active {
    color: white;
}

.react-calendar__tile--active>abbr {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0px 8px;
    gap: 8px;
    width: 44px;
    height: 44px;
    background: #41B8D4;
    border-radius: 43px;
    flex: none;
    order: 1;
    flex-grow: 0;
    top: 8px;
    right: 8px;
}
.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
    background: #1087ff;
}
.react-calendar--selectRange .react-calendar__tile--hover {
    background-color: #e6e6e6;
}
